import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Chip, Stack } from "@mui/material";
import { isNumber } from "lodash";
import { type ReactElement } from "react";

interface ChipProps {
  children: React.ReactNode;
  icon?: ReactElement;
}

export function DarkGreenChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#1B5E20" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#2E7D321A",
      }}
    />
  );
}

export function LightGreenChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#388E3C" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#81C7841A",
      }}
    />
  );
}

export function LightYellowChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#F9A825" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#FFB3001A",
      }}
    />
  );
}

export function DarkYellowChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#F57F17" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#FFB3001A",
      }}
    />
  );
}

export function LightOrangeChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#EF6C00" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#FF98001A",
      }}
    />
  );
}

export function DarkOrangeChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#E65100" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#FF98001A",
      }}
    />
  );
}

export function RedChip({ children, icon }: ChipProps) {
  return (
    <Chip
      icon={icon}
      label={
        <span>
          <Text bold sx={{ color: "#D32F2F" }}>
            {children}
          </Text>
        </span>
      }
      variant="filled"
      sx={{
        borderRadius: "5px",
        backgroundColor: "#F443361A",
      }}
    />
  );
}

interface ConsequenceChipProps {
  points: number;
}

function ConsequenceChip({ points }: ConsequenceChipProps) {
  const isPositive = points > 0;
  const formattedPoints = isPositive ? `+${points}` : `${points}`;

  return isPositive ? (
    <DarkGreenChip>{`${formattedPoints} pts`}</DarkGreenChip>
  ) : (
    <RedChip>{`${formattedPoints} pts`}</RedChip>
  );
}

export interface ScorePolicyEntryProps {
  title: string;
  consequence?: number | string;
}

export function ScorePolicyEntry(policy: ScorePolicyEntryProps) {
  const { title, consequence } = policy;
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Text>{title}</Text>
      {isDefined(consequence) &&
        (isNumber(consequence) ? (
          <ConsequenceChip points={consequence} />
        ) : (
          <RedChip>{consequence}</RedChip>
        ))}
    </Stack>
  );
}
